import { Component, EventEmitter, Input, OnInit, Output, computed, effect, inject, signal } from '@angular/core';
import { AssignmentsService } from './assignments.service';
import { Assignments, ModuleIds, SyncRolePositionModel, SyncUsersAndRolesModel } from './assignments.model';
import { SearchViewModel } from 'src/app/models/module/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { debounceTime, single, take } from 'rxjs';
import { DEBOUNCE_TIMES } from 'src/app/shared/consts';
import { CipoFormModule } from 'src/app/shared/modules';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { CipoSelectComponent } from 'src/app/shared/components/fields/cipo-select/cipo-select.component';
import { CipoFieldTypes, CipoListControl } from 'src/app/shared/components/fields/common';
import { KeyValueModel, KeyValueType } from 'src/app/models/common';
import { TenantSettingsEnum } from '../tenant-settings/tenant-settings.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app.state';
import { tenantSelectors } from 'src/app/store/tenant';
import { NotificationService, UserService, UtilsService } from 'src/app/shared/services';
import { SelectUsersParameters } from './assign-users-dialog/assign-users-dialog.model';
import { AssignUsersDialogComponent } from './assign-users-dialog/assign-users-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PrimaryUsersDialogComponent } from './primary-users-dialog/primary-users-dialog.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cipo-assignments',
  standalone: true,
  imports: [
    TranslateModule,
    CipoFormModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    CipoSelectComponent,
    DragDropModule,
  ],
  providers: [AssignmentsService, FormControl],
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
})
export class AssignmentsComponent implements OnInit {
  translate = inject(TranslateService);
  assignmentsService = inject(AssignmentsService);
  notification = inject(NotificationService);
  store = inject(Store<AppState>);
  utilsService = inject(UtilsService);
  userService = inject(UserService);

  @Output('roleschanged') rolesChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('assignmentschanged') assignmentsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onclose') onClose: EventEmitter<void> = new EventEmitter<void>();
  @Input('entityinstanceid') entityInstanceId: number;
  @Input('moduleid') moduleId: number;
  @Input('hasupdate') hasUpdate: boolean;

  MODULE_IDS = ModuleIds;
  assignments: Assignments[] = [];
  initialSearchAssignments: Assignments[] = [];
  initialAssignments: Assignments[] = [];
  searchModel: SearchViewModel = {};
  searchControl = new FormControl('');
  propagateHierarchy = signal(false);
  loading = signal(false);
  saving = signal(false);
  ordering = signal(false);
  userRateEnabled = signal(false);
  userAllocationEnabled = signal(false);
  assignmentDetailsPercent: number = 70;
  roles: KeyValueType<number>[] = [];
  arrayControls: Record<number, CipoListControl> = {};
  disabledForm = computed(() => this.ordering() || this.saving() || this.entityInstanceLocked());

  entityInstanceLocked = signal(false);
  @Input('entityinstancelocked')
  set _entityInstanceLocked(value: boolean) {
    this.entityInstanceLocked.set(value);

    if (Object.keys(this.arrayControls).length) {
      this.disableEnableControls(value);
    }
  }

  constructor(private dialog: MatDialog) {
    effect(() => {
      const disabled = this.ordering() || this.saving();

      // Check to avoid multiplication
      if (Object.values(this.arrayControls)[0]?.disabled === disabled) return;

      this.disableEnableControls(disabled);
    });
  }

  ngOnInit(): void {
    this.store
      .select(tenantSelectors.getSettingsValue([TenantSettingsEnum.user_allocation, TenantSettingsEnum.user_rate]))
      .subscribe(tenantSettings => {
        this.userRateEnabled.set(
          this.utilsService.convertValueToBoolean(
            tenantSettings.find(tenantSetting => tenantSetting.id == TenantSettingsEnum.user_rate).value,
          ),
        );
        this.userAllocationEnabled.set(
          this.utilsService.convertValueToBoolean(
            tenantSettings.find(tenantSetting => tenantSetting.id == TenantSettingsEnum.user_allocation).value,
          ),
        );

        if (this.userRateEnabled()) {
          this.assignmentDetailsPercent -= 10;
        }
        if (this.userAllocationEnabled()) {
          this.assignmentDetailsPercent -= 10;
        }
      });
    this.searchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.default)).subscribe(value => this.search(value));
    this.assignmentsService.getRoles().subscribe(r => {
      this.roles = r.map(({ key, value }) => ({ key, value }));
      this.loadData();
    });
  }

  disableEnableControls(disabled: boolean) {
    Object.values(this.arrayControls).forEach(control => {
      if (disabled) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  loadData() {
    // clear criteria and assignments
    this.searchModel.criteria = '';
    this.assignments = [];
    this.loading.set(true);

    this.assignmentsService.getAssignments(this.searchModel, this.entityInstanceId).subscribe(a => {
      this.assignments = a.data;
      this.initialSearchAssignments = this.assignments;
      this.initialAssignments = structuredClone(this.assignments);
      this.generateControls();
      this.loading.set(false);
    });
  }

  generateControls() {
    this.arrayControls = {};

    for (let index = 0; index < this.assignments.length; index++) {
      const control = new CipoListControl(this.assignments[index].roleIds, {
        label: 'assignments.roles',
        multiple: true,
        type: CipoFieldTypes.Select,
        options: this.roles,
      });

      control.valueChanges.pipe(take(1)).subscribe(x => {
        this.assignments[index].edited = true;
        this.rolesChanged.emit(true);
      });

      this.arrayControls[this.assignments[index].userId] = control;
    }

    if (this.entityInstanceLocked()) {
      this.disableEnableControls(true);
    }
  }

  search(criteria: string) {
    if (!criteria) {
      this.assignments = this.initialSearchAssignments;
      return;
    }

    this.assignments = this.initialSearchAssignments.filter(
      row =>
        row.userName.toString().toLowerCase().includes(criteria.toLowerCase()) ||
        row.userOrgName.toString().toLowerCase().includes(criteria.toLowerCase()) ||
        row.userEmail.toString().toLowerCase().includes(criteria.toLowerCase()) ||
        row.roles?.some(r => r.value?.toString()?.toLowerCase()?.includes(criteria.toLowerCase())),
    );
  }

  getRoles(roleIds: number[]) {
    const roles = this.roles.filter(r => roleIds.includes(r.key));
    return roles?.map(a => a.value)?.join(', ') ?? '';
  }

  deleteAssignment(assignment: Assignments) {
    this.rolesChanged.emit(true);

    assignment.deleting = true;
    this.arrayControls[assignment.userId].setValue([]);
    this.arrayControls[assignment.userId].disable();
  }

  restoreDeleteAssignment(assignment: Assignments) {
    assignment.deleting = false;
    this.arrayControls[assignment.userId].setValue(assignment.roleIds);
    this.arrayControls[assignment.userId].enable();
  }

  somethingChange(assignment: Assignments) {
    assignment.edited = true;
    this.rolesChanged.emit(true);
  }

  saveAssignments() {
    // clear search on save
    this.clearInput();
    this.search('');

    if (this.assignments?.length) {
      this.saving.set(true);

      for (let index = 0; index < this.assignments.length; index++) {
        const roleIds = this.arrayControls[this.assignments[index].userId]?.value ?? [];

        this.assignments[index].roleIds = roleIds;
        this.assignments[index].roles = this.roles.filter(r => roleIds.includes(r.key));
      }

      const syncModel: SyncUsersAndRolesModel = {
        id: this.entityInstanceId,
        propagateOnHierarchy: this.propagateHierarchy(),
        users: this.assignments.filter(a => !a.deleting),
      };

      this.assignmentsService.syncAssignments(syncModel).subscribe({
        complete: () => {
          this.notification.success('assignments.successfully');
          this.loadData();
          this.rolesChanged.emit(false);
          this.assignmentsChanged.emit(true);
          this.saving.set(false);
          this.propagateHierarchy.set(false);
        },
        error: () => {
          this.saving.set(false);
        },
      });
    }
  }

  getTotalAllocationHours() {
    return this.initialSearchAssignments
      .map(d => d.allocationHoursPerMonth ?? 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  clearInput() {
    this.searchControl.reset();
  }

  openSelectUsersModel() {
    const params: SelectUsersParameters = {
      entityInstanceId: this.entityInstanceId,
      existingUsers: this.initialSearchAssignments.filter(a => !a.deleting).map(a => a.userId),
    };

    this.dialog
      .open(AssignUsersDialogComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        ...this.userService.getResponsiveDialogSize().md,
        height: "65%",
        data: params,
      })
      .afterClosed()
      .subscribe((userIds: number[]) => {
        if (userIds?.length) {
          // clear search on save
          this.clearInput();

          this.assignmentsChanged.emit(true);
          this.loadData();
        }
      });
  }

  openPrimaryUsersModel() {
    this.dialog
      .open(PrimaryUsersDialogComponent, {
        panelClass: ['cipo-dialog', 'classic', 'full-height'],
        height: '770px',
        data: this.entityInstanceId,
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .subscribe((status: boolean) => {
        if (status) {
          this.loadData();
        }
      });
  }

  drop(asignment: Assignments, event: CdkDragDrop<KeyValueModel<number, string>[]>) {
    moveItemInArray(asignment.roles, event.previousIndex, event.currentIndex);
  }

  saveOrdering(assignment: Assignments) {
    // set order
    for (let index = 0; index < assignment.roles.length; index++) {
      assignment.roles[index].order = index + 1;
    }

    const model: SyncRolePositionModel = {
      contractId: this.entityInstanceId,
      userId: assignment.userId,
      roles: assignment.roles,
    };

    this.assignmentsService.syncRolePosition(model).subscribe(() => {
      this.notification.success('assignments.rolesSuccessfully');
      this.ordering.set(false);
      this.loadData();
    });
  }

  anyAssignmentsChanged() {
    return this.initialSearchAssignments.some(a => a.deleting || a.edited);
  }

  discardChanges() {
    // clear search on save
    this.clearInput();
    this.assignments = structuredClone(this.initialAssignments);
    this.initialSearchAssignments = this.assignments;
    this.propagateHierarchy.set(false);
    this.generateControls();
    this.rolesChanged.emit(false);
  }

  onCloseEmit() {
    this.onClose.emit();
  }
}
