import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Explorer1", function (Model, $q, $http, ACTIONS, Manager, Message, $mdDialog, userService, URI, GenericClass,
    FileSaver, $state, OFFICE_MIME_TYPES, officeService, Permissions) {

    const ROOT_NODE_ID = -1;
    const SHARED_WITH_ME_NODE_ID = -2;
    const RECYCLE_BIN_NODE_ID = -10;
    const ARCHIVE_NODE_ID = -15;
    const ARCHIVE_FILE_NODE_ID = -20;
    const CONTRACT_MODULE_ID = -1;
    const PROJECT_MODULE_ID = -10;
    const PROGRAM_MODULE_ID = -23;

    //constructor extending Model
    var Explorer1 = Model.extend(function (obj) {
        var self = this;

        self.context = {};

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                self[key] = obj[key];
            }
        }
        self.additionalURLParams = self.urlsData.tree.urlParams;
        self.additionalItemParams = self.urlsData.itemParams;

        Object.defineProperty(self, 'operations', {
            get: function () { return userService.getOperationsFor(self.abbreviation); }
        });

        self.clearFolderTree();

        self.currentEntityInstanceId = self.getEntityInstanceIdParent();
        self.rootModuleId = self.getRootModuleId();
        self.isProjectFiles = self.abbreviation == 'FCM';
        self.isExtraPermissions = userService.system.modules[self.abbreviation]?.extraPermissions || false;

        Object.defineProperty(self, 'folderTree', {
            get: function () {
                return self.treeStructure;
            }
        })

        self.nodeType = 0;
        self.lookupThumb = {};
        self.lookupManager = {};
        self.pdfMarkupMimeTypes = [];

        self.resizerOptions = {
            resizerTop: 10,
            resizerBottom: 10,
            resizerLeft: "#tree",
            resizerRight: ["#content"],
        };
    });

    Explorer1.prototype.clearFolderTree = function () {
        var self = this;
        var root = this.makeRoot();

        self.expandedFolders = [];
        self.selectedFolder = {};
        self.treeStructure = [];
        self.lookupFolders = {};
        self.lookupFolders[root.id + "_1"] = root;
    }

    Explorer1.prototype.makeRoot = function () {
        var self = this;
        return { id: self.isProjectFiles ? self.currentEntityInstanceId : ROOT_NODE_ID, entityInstanceId: self.currentEntityInstanceId, folders: [], isLoadingChildren: true, itemModuleId: self.getRootModuleId(), name: self.isProjectFiles ? self.getCurrentEntityInstanceNameParent() : "..." };
    }

    Explorer1.prototype.reinit = function () {
        var self = this;
        var p = $q.defer();
        self.clearFolderTree();
        self.currentEntityInstanceId = self.getEntityInstanceIdParent();
        self.rootModuleId = self.getRootModuleId();
        self.init().finally(function () { p.resolve(); });
        return p.promise;
    }

    Explorer1.prototype.functions = {
        setClass: function (mimetype) {
            return mimetype.replace(/\//g, '-').replace(/\./g, '-');
        }
    }

    Explorer1.prototype.setParams = function (params) {
        var self = this;
        var params = params || {};
        if (self.urlsData.tree.urlParams)
            for (var key in self.urlsData.tree.urlParams) {
                if (self.urlsData.tree.urlParams.hasOwnProperty(key) && !params[key]) {
                    params[key] = self.urlsData.tree.urlParams[key];

                    if (key == 'entityInstanceId' && !self.urlsData.tree.urlParams[key]) {
                        params[key] = self.currentEntityInstanceId;
                    }

                    if (key == 'id') {
                        params[key] = self.clearFolderId(params[key]);
                    }
                }
            }

        return params;
    }

    Explorer1.prototype.init = function () {
        var self = this;
        var p = $q.defer();
        self.breadcrumb = [];
        self.breadcrumbForSearch = [];

        if (!self.lookupFolders[self.currentEntityInstanceId + "_1"]) {
            self.lookupFolders[self.currentEntityInstanceId + "_1"] = { id: self.isProjectFiles ? self.currentEntityInstanceId : ROOT_NODE_ID, entityInstanceId: self.currentEntityInstanceId, folders: [], isLoadingChildren: true, itemModuleId: self.getRootModuleId(), name: self.isProjectFiles ? self.getCurrentEntityInstanceNameParent() : "..." };
        }

        self.selectedFolder = self.lookupFolders[self.currentEntityInstanceId + "_1"];

        self.populateBreadcrumbForSearch(self.selectedFolder);

        self.getPdfMarkupMimeTypes()
            .then(function (res) {
                self.pdfMarkupMimeTypes = res;
                var searchObject = (self.manager || {}).searchObject ? angular.copy(self.manager.searchObject) : null;
                self.loadManager();
                self.manager.searchObject = searchObject;
                if (self.destinationFolderFromSearch) {
                    self.getChildren(self.selectedFolder, true)
                        .then(function () {
                            self.getTreeParents(self.destinationFolderFromSearch)
                                .finally(function () {
                                    self.destinationFolderFromSearch = null;
                                    p.resolve();
                                });
                        })
                }
                else if (((self.manager || {}).searchObject || {}).isSearchView && self.isChangeEntityInstance) {
                    self.getChildren(self.selectedFolder, true)
                        .then(function () {
                            self.isChangeEntityInstance = false;
                            self.restoreSearch();
                            p.resolve();
                        })
                }
                else {
                    self.getFolderContent().finally(function () { p.resolve() });
                }
            });

        return p.promise;
    }

    Explorer1.prototype.getPdfMarkupMimeTypes = function () {
        var self = this;
        var p = $q.defer();
        if (!self.pdfMarkupMimeTypes.length)
            self[URI.PDF_MARKUP.GET_MIME_TYPES.method](URI.PDF_MARKUP.GET_MIME_TYPES)
                .then(function (res) {
                    p.resolve(res);
                })
                .catch(function (err) {
                    p.reject();
                });
        else p.resolve(self.pdfMarkupMimeTypes);

        return p.promise;
    }

    Explorer1.prototype.isInExpandedFolders = function (id, parentsEIString) {
        var folderInExpanded = false;
        for (var i = 0; i < (this.expandedFolders || []).length; i++) {
            if (this.expandedFolders[i].id == id && this.expandedFolders[i].parentsEIString == parentsEIString) {
                folderInExpanded = this.expandedFolders[i];
                break;
            }
        }
        return folderInExpanded;
    }

    Explorer1.prototype.parseManagerData = function (data) {

        var setClass = function (mimetype) {
            return (mimetype || '').replace(/\//g, '-').replace(/\./g, '-');
        }

        var self = this;
        var data = data || [], retval = [];

        if (self.manager.nodeType == ARCHIVE_NODE_ID) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].createdOn) {
                    data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                }
                retval.push(data[i]);
            }
        }
        else if (self.manager.nodeType == ARCHIVE_FILE_NODE_ID) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].createdOn) {
                    data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                }
                if (data[i].fileSize) {
                    if (data[i].fileSize < 1000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000).toFixed(2) + 'KB</div>';
                    if (data[i].fileSize >= 1000000 && data[i].fileSize < 1000000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000000).toFixed(2) + 'MB</div>';
                    if (data[i].fileSize >= 1000000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000000000).toFixed(2) + 'GB</div>';
                }
                if (data[i].fileSize == 0) data[i].fileSize = '<div class="text-right"> Failed </div>';
                data[i]['entityInstanceId'] = self.currentEntityInstanceId;
                retval.push(data[i]);
            }
        }
        else {
            for (var i = 0; i < data.length; i++) {
                var sharedClass = data[i].id == SHARED_WITH_ME_NODE_ID ? "sharedFolder" : "";
                var folderIcon = data[i].isLocked && data[i].isFolder ? " mdi-folder-marker" : "";
                if (data[i].hasShared) {
                    data[i]._hasShared = '<i class="mdi mdi-account-multiple center"></i>'
                }
                else {
                    data[i]._hasShared = '-';
                }
                if (data[i].createdOn) { 
                    data[i].createdOnNotFormatted = data[i].createdOn;
                    data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                }
                if (data[i].updatedOn) { 
                    data[i].updatedOnNotFormatted = data[i].updatedOn;
                    data[i].updatedOn = moment(data[i].updatedOn).format(userService.formats.date);
                }
                data[i]._name = data[i].name;
                data[i]._big_name = data[i].name;

                if (data[i].parents && typeof data[i].parents == "string") {
                    data[i].parents = data[i].parents.split(',').map(Number);
                    data[i].parents.pop();
                }

                if (data[i].contractName && data[i].contractNo) data[i].contractName = data[i].contractNo + " - " + data[i].contractName;

                if (data[i].isFolder) {
                    data[i]._name = '<span class="fileInfo folderIcon ' + sharedClass + folderIcon + '">'
                        + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';
                    data[i]._big_name = '<span class="fileInfo folderIcon ' + sharedClass + folderIcon + ' ellipsedText">'
                        + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';
                    data[i].length = '-';
                }
                else if (data[i].itemModuleId) {
                    if (data[i].itemModuleId == CONTRACT_MODULE_ID) {
                        data[i].iconId = userService.system.modules['CT'].iconId;
                        data[i].iconColor = userService.system.modules['CT'].color;
                    }
                    else if (data[i].itemModuleId == PROJECT_MODULE_ID) {
                        data[i].iconId = userService.system.modules['P'].iconId;
                        data[i].iconColor = userService.system.modules['P'].color;
                    }

                    var iconName = userService.system.icons.byKey.get(data[i].iconId || 0)?.name || userService.system.icons.byKey.get(0).name;
                    var color = data[i].iconColor || 'black';
                    data[i]._name = '<mat-icon class="tableIcon mat-icon material-icons mat-ligature-font" style="color:' + color + '" fonticon="' + iconName + '"></mat-icon>' + data[i].name;
                    data[i].length = '-';
                }
                else {
                    if (data[i].thumbnail50)
                        data[i]._name = '<img class="tableSmallTmb" src="data:'
                            + data[i].mimeType + ';base64,' + data[i].thumbnail50 + '" />'
                            + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                            + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                            + data[i].name;
                    else data[i]._name = '<span class="fileInfo '
                        + setClass(data[i].mimeType) + '">'
                        + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';

                    if (data[i].thumbnail200)
                        data[i]._big_name = '<span class="thumbWrapper"><img src="data:'
                            + data[i].mimeType + ';base64,'
                            + data[i].thumbnail200 + '" />'
                            + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                            + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                            + '</span><span class="ellipsedText fileInfo '
                            + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                    else data[i]._big_name = '<span class="thumbWrapper bigIcon fileInfo '
                        + setClass(data[i].mimeType) + '">'
                        + (data[i].global > 1 ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                        + '</span><span class="ellipsedText fileInfo '
                        + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                }

                if (!data[i].isFolder && data[i].length) {
                    if (data[i].length < 1000000) data[i].length = '<div class="text-right">' + (data[i].length / 1000).toFixed(2) + 'KB</div>';
                    if (data[i].length >= 1000000) data[i].length = '<div class="text-right">' + (data[i].length / 1000000).toFixed(2) + 'MB</div>';
                }

                retval.push(data[i]);
            }
        }
        return retval;
    }

    Explorer1.prototype.changeEntityInstance = function (entityInstanceId, entityInstanceName) {
        //userService.setContract(entityInstanceId);
        //Menu.triggerRefresh();
        Message.warning('The contract was changed to ' + entityInstanceName);
        self.isChangeEntityInstance = true;
    };

    Explorer1.prototype.getFolderFromLookup = function (folderId, parentsEIString) {
        var self = this;

        if (folderId && self.lookupFolders[folderId + "_1"]) {
            return self.lookupFolders[folderId + "_1"];
        }
        else if (folderId && self.lookupFolders[(parentsEIString || '') + '|' + folderId.toString()]) {
            return self.lookupFolders[(parentsEIString || '') + '|' + folderId.toString()];
        }

        return null;
    }

    Explorer1.prototype.loadManager = function () {
        var self = this;

        self.manager = new Manager({
            objectsPerPage: 20,
            dataWrapper: "data",
            dataURL: self.urlsData.manager.url,
            otherParams: { id: self.clearFolderId(self.selectedFolder.id) },
            dataParams: { mimeTypes: self.isPdfMarkup ? self.pdfMarkupMimeTypes : [], isGlobal: false, showAllPhotos: false },
            urlParams: self.setParams(),
            fileManagerMenu: true,
            noTabletVersion: true,
            options: {
                multiselect: true
            },
            menuClass: 'fileManagerMenu',
            gridClass: 'fileManagerGrid',
            search: self.managerSetup.search,
            searchColumns: self.managerSetup.searchColumns || null,
            globalSearchColumns: self.managerSetup.globalSearchColumns || null,

            hasGlobalSearchToggle: self.hasGlobal ? true : false,
            globalSearchParam: "isGlobal",
            isGlobalSearch: false,

            layout: self.layout,
            rowOnClick: function (row) {
                if (self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                    row.selected = !row.selected;
                    return;
                }
                if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                    //self.archiveModules(row);
                    row['id'] = ARCHIVE_FILE_NODE_ID;
                    row['folderPosition'] = 1;
                    row['parentName'] = 'Archives';
                    row['name'] = row.archiveName;
                    row['parents'] = [-15],
                        row['folderPath'] = 'Root/Archives/Teste/';// + row.archiveName;
                    self.getFolderContent(row);
                }
                else if (row.itemModuleId) {
                    self.currentEntityInstanceId = self.getEntityInstanceIdFromDatalist(row);
                    self.populateBreadcrumbForSearch(row);
                    self.getFolderContent(row);
                }
                else if (this.layout == 1 || this.layout == 2) {
                    if (row.isFolder) {
                        (self.manager.searchObject || {}).isSearchView = false;
                        if (!row.entityInstanceId || row.entityInstanceId == self.currentEntityInstanceId) {
                            if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                            else self.getFolderContent(row);
                        } else {
                            self.changeEntityInstance(row.entityInstanceId, row.name);
                            self.destinationFolderFromSearch = row;
                        }

                    } else {
                        if (self.isPdfMarkup) {
                            self.pdfMarkup([row]);
                        }
                        else {
                            var viewPdfMarkup = self.pdfMarkupMimeTypes.includes(row.mimeType);
                            var viewOffice = OFFICE_MIME_TYPES.includes(row.mimeType);

                            if (viewPdfMarkup || viewOffice) {
                                self.view(row);
                            }
                            else {
                                self.fileDetails(row);
                            }
                        }
                    }
                } else {
                    row.selected = !row.selected;
                }

            },
            rowOnDblClick: function (row) {
                if (self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                    row.selected = !row.selected;
                    return;
                }
                if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                    self.archiveModules(row);
                }
                else if (row.itemModuleId) {
                    self.currentEntityInstanceId = self.getEntityInstanceIdFromDatalist(row);
                    self.populateBreadcrumbForSearch(row);
                    self.getFolderContent(row);
                }
                else if (this.layout == 1 || this.layout == 2) {
                    if (row.isFolder) {
                        self.editFolder(row);
                    } else {
                        self.isPdfMarkup
                            ? self.pdfMarkup([row])
                            : self.fileDetails(row);
                    }
                } else {
                    if (row.isFolder) {
                        if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                        else self.getFolderContent(row);
                    } else {
                        self.isPdfMarkup
                            ? self.pdfMarkup([row])
                            : self.fileDetails(row);
                    }
                }
            },
            parseData: function (data) {
                return self.parseManagerData(data);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.BACK,
                    conditionOnTop: function () {
                        if ((self.selectedFolder.id != self.currentEntityInstanceId || self.currentEntityInstanceId != self.getEntityInstanceIdParent()) && !(self.manager.hasFilters || self.manager.criteria) && self.selectedFolder.id != ROOT_NODE_ID)
                            return true;
                        else return false;
                    },
                    click: function () {
                        if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                            self.selectedFolder.id = ARCHIVE_NODE_ID;
                            self.getFolderContent(self.selectedFolder);
                        }
                        else {
                            self.getFolderContent(self.getFolderFromLookup(self.selectedFolder.parentId || self.selectedFolder.parentEntityInstanceId, self.selectedFolder.parentsEIString) || self.lookupFolders[self.currentEntityInstanceId + "_1"], true);
                        }
                    }
                },
                {
                    setProperties: ACTIONS.NEWFOLDER,
                    type: 'primary',
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if ((self.operations.Create || self.selectedFolder?.operations?.Create || self.selectedFolder?.operations?.Update)
                            && !self.isNonEditableFolder(self.selectedFolder)
                            && self.selectedFolder.id != -2
                            && !self.selectedFolder.isLocked && !(self.manager.hasFilters || self.manager.criteria)) return true;
                        else return false;
                    },
                    click: function () {
                        self.editFolder();
                    }
                },
                {
                    setProperties: ACTIONS.OPENUPLOADDIALOG,
                    name: "Upload",
                    // 'multiple' drives whether dialog can open over another (Must be true for pdfMarkupAddController)
                    multiple: true,
                    mimeTypeInUrl: true,
                    noLabel: true,
                    accept: self.accept || '*',
                    urlParams: function () {
                        return self.setParams({ parentId: self.selectedFolder.itemModuleId ? ROOT_NODE_ID : self.clearFolderId(self.selectedFolder.id), entityInstanceId: self.currentEntityInstanceId });
                    },
                    generateFileIdUrl: self.urlsData.files.generateFileIdURL,
                    startUploadUrl: self.urlsData.files.startUploadURL,
                    uploadChunkUrl: self.urlsData.files.uploadChunkURL,
                    saveFileInfoUrl: self.urlsData.files.saveFileInfoURL,
                    uploadUrl: self.urlsData.files.uploadFileURL,
                    information: self.isProjectFiles ? 'This file will be added on a ' + self.getEntityInstanceModuleName() : '',
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if ((self.operations.Create || self.selectedFolder?.operations?.Create || self.selectedFolder?.operations?.Update)
                            && self.selectedFolder.id != -2
                            && !self.isNonEditableFolder(self.selectedFolder)
                            && !self.selectedFolder.isLocked
                            && !(self.manager.hasFilters || self.manager.criteria))
                            return true;
                        else return false;
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    conditionOnTop: function () {
                        return self.manager.selectedRows().length == 0;
                    },
                    click: function () {
                        self.getFolderContent();
                    }
                },
                {
                    setProperties: ACTIONS.PDF_MARKUP,
                    noLabel: true,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (row.id == RECYCLE_BIN_NODE_ID && row.isFolder) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        if (self.abbreviation == 'FCM'
                            && userService.system.modules["PDFM"]
                            && !self.isNonEditableFolder(self.selectedFolder)
                            && self.canFilesDoActionPdfMarkup([row]))
                            return true;

                        return false;
                    },
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        if (self.abbreviation == "FCM"
                            && !self.isNonEditableFolder(self.selectedFolder)
                            && self.canFilesDoActionPdfMarkup(self.manager.selectedRows()))
                            return true;

                        return false;
                    },
                    click: function (rows) {
                        if (rows.length <= 0) {
                            Message.info('Please select PDF files');
                            return;
                        }

                        if (self.isPdfMarkup) {
                            self.pdfMarkup(rows);
                        } else {
                            var isAllPdfs = true;
                            for (var i = 0; i < rows.length; i++) {
                                if (self.pdfMarkupMimeTypes.indexOf(rows[i].mimeType) < 0) {
                                    isAllPdfs = false;
                                    Message.error('Please select only PDF, JPG, or PNG files.')
                                    return;
                                }
                            }
                            self.pdfMarkup(rows);
                        }
                    }
                },
                {
                    setProperties: ACTIONS.PDF_MARKUP_ROW,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        if (self.isNonEditableFolder(self.selectedFolder)
                            || !self.canFilesDoActionPdfMarkup([row])
                        ) return false;
                        var isProjectFiles = self.abbreviation == 'FCM';
                        var hasPdfMarkupPermissions = userService.system.modules["PDFM"];
                        var canMarkup = self.pdfMarkupMimeTypes.indexOf(row.mimeType) != -1;

                        return isProjectFiles && hasPdfMarkupPermissions && canMarkup;
                    },
                    conditionOnTop: function () {
                        return false;
                    },
                    click: function (row) {
                        self.pdfMarkup([row]);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        var viewPdfMarkup = self.pdfMarkupMimeTypes.includes(row.mimeType) && !self.isNonEditableFolder(self.selectedFolder);
                        var viewOffice = OFFICE_MIME_TYPES.includes(row.mimeType);
                        return viewPdfMarkup || viewOffice;
                    },
                    click: function (row) {
                        self.view(row);
                    }
                },
                {
                    setProperties: ACTIONS.PROPERTIES,
                    name: 'Properties',
                    noLabel: true,
                    condition: function (row) {
                        return row.isFolder
                            || self.selectedFolder.id == ARCHIVE_NODE_ID
                            || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID
                            ? false : true;
                    },
                    click: function (row) {
                        if (row.isFolder) {
                            if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                            else self.getFolderContent(row);
                        }
                        else if (row.itemModuleId) {
                            self.currentEntityInstanceId = self.getEntityInstanceIdFromDatalist(row);
                            self.populateBreadcrumbForSearch(row);
                            self.getFolderContent(row);
                        }
                        else self.fileDetails(row);
                    }
                },
                {
                    setProperties: ACTIONS.OPEN_FOLDER,
                    noLabel: true,
                    condition: function (row) {
                        return !row.isFolder
                            || self.isNonEditableFolder(self.selectedFolder)
                            ? false : true;
                    },
                    click: function (row) {
                        if (row.isFolder) {
                            (self.manager.searchObject || {}).isSearchView = false;
                            if (!row.entityInstanceId || row.entityInstanceId == self.currentEntityInstanceId) {
                                if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                                else self.getFolderContent(row);
                            } else {
                                self.changeEntityInstance(row.entityInstanceId, row.name);
                                self.destinationFolderFromSearch = row;
                            }
                        }
                        else self.fileDetails(row);
                    }
                },
                {
                    setProperties: ACTIONS.EDIT_FOLDER,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.isNonEditableFolder(row) && row.isFolder) return false;
                        if (!row.isFolder) return false;
                        else return true;
                    },
                    name: self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_NODE_ID
                        || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID ? "View folder" : "Properties",
                    click: function (row) {
                        self.editFolder(row);
                    }
                },
                {
                    setProperties: ACTIONS.EDIT,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (row.itemModuleId) return false;
                        if (self.selectedFolder.id == -2
                            || self.isNonEditableFolder(self.selectedFolder)
                            || row.id == -2
                            || !row.operations?.Update
                            || row.isFolder
                            || row.isLocked) return false;
                        else return true;
                    },
                    name: "Rename file",
                    click: function (row) {
                        self.editItem(row);
                    }
                },
                {
                    setProperties: ACTIONS.SET_AVATAR,
                    condition: function (row) {
                        if (self.urlsData.files.setAsUserAvatar && row.setUserLogo) return true;
                        else return false;
                    },
                    click: function (row) {
                        self.setAsUserAvatar(row);
                    }
                },
                {
                    setProperties: ACTIONS.SET_LOGO,
                    condition: function (row) {
                        if (self.urlsData.files.setAsCompanyLogo && row.setCompanyLogo) return true;
                        else return false;
                    },
                    click: function (row) {
                        self.setAsCompanyLogo(row);
                    }
                },
                {
                    setProperties: ACTIONS.DOWNLOAD,
                    condition: function (row) {
                        if (row.itemModuleId) return false;
                        if (row.isFolder || self.isNonEditableFolder(self.selectedFolder))
                            return false;
                        else return true;
                    },
                    click: function (row) {
                        self.downloadFile(row);
                    }
                },
                {
                    setProperties: ACTIONS.DOWNLOAD_ZIP,
                    conditionOnTop: function () {
                        if (self.selectedFolder.id == ARCHIVE_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                            return false;
                        }
                        else if (self.manager.selectedRows().length) {
                            return true
                        };
                    },
                    condition: function (row) {
                        if (self.selectedFolder.id == ARCHIVE_NODE_ID || row.itemModuleId) {
                            return false;
                        }
                        else {
                            return true
                        };
                    },
                    click: function (rows) {
                        if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                            self.downloadArchiveFile(rows);
                        }
                        else {
                            self.downloadZipFile(rows);
                        }
                    }
                },
                {
                    setProperties: ACTIONS.MOVE,
                    isGroup: false,
                    condition: function (row) {
                        if (row.id == RECYCLE_BIN_NODE_ID && row.isFolder) return false;
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (row.itemModuleId || row.isReadOnly || self.isExtraPermissions) return false;
                        if (row.id == ARCHIVE_NODE_ID) return false;
                        if (self.abbreviation == 'FCM' && !row.operations?.UpdateGlobal && row.global > 1) return false;
                        if (self.selectedFolder.id == -2
                            || self.isNonEditableFolder(self.selectedFolder)
                            || !row.operations?.Update
                            || row.isLocked
                        ) return false;
                        else return true;
                    },
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID || self.isExtraPermissions) return false;
                        if (self.selectedFolder.id == -2
                            || self.isNonEditableFolder(self.selectedFolder)
                            || self.selectedFolder.isLocked
                            || !self.manager.selectedRows().length
                            || self.selectedFilesOrCurrentFolder().some(f =>
                                f.isLocked
                                || self.isNonEditableFolder(f)
                            )
                            || !self.operations.Update
                        ) return false;
                        else return true;
                    },
                    click: function (rows) {
                        self.moveItems(rows);
                    }
                },
                {
                    setProperties: ACTIONS.SHARE,
                    conditionOnTop: function () {
                        if (!self.hasShare
                            || !self.operations.Update
                            || !self.manager.selectedRows().length)
                            return false;
                        else return true;
                    },
                    condition: function (row) {
                        if (row.isFolder
                            || !self.hasShare
                            || !row.operations?.Update)
                            return false;
                        else return true;
                    },
                    click: function (rows) {
                        self.shareItems(rows);
                    }
                },
                {
                    setProperties: ACTIONS.UNSHARE,
                    conditionOnTop: function () {
                        if (!self.hasShare
                            || !self.operations.Update
                            || !self.manager.selectedRows().length)
                            return false;
                        else return true;
                    },
                    condition: function (row) {
                        if (row.isFolder
                            || !self.hasShare
                            || !row.isShared
                            || !row.operations?.Update)
                            return false;
                        else return true;
                    },
                    click: function (rows) {
                        self.unshareItems(rows);
                    }
                },
                /*
                {
                    setProperties: ACTIONS.GLOBAL,
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.abbreviation == 'FCM' && !self.operations['Create Global'] && !self.operations['Create Global']) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        if (!self.hasGlobal
                            || !self.operations.Update
                            || self.anySelectedFileIsLocked()
                            || self.isNonEditableFolder(self.selectedFolder)
                            || !self.manager.selectedRows().length
                            || self.manager.selectedRows().some(f =>
                                f.isFolder
                                || !self.hasGlobal
                                || self.hasGlobal && f.global > 1
                                || f.isLocked)
                        )
                            return false;
                        else return true;
                    },
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.abbreviation == 'FCM' && !row.operations?.CreateGlobal) return false;
                        if (self.versionId) return false;
                        if (row.isFolder
                            || !self.hasGlobal
                            || row.global > 1
                            || row.isLocked
                            || self.isNonEditableFolder(self.selectedFolder)
                        )
                            return false;
                        else return true;
                    },
                    click: function (rows) {
                        self.setGlobal(rows);
                    }
                },*/
                {
                    setProperties: ACTIONS.UNSET_GLOBAL,
                    conditionOnTop: function () {
                        return false;
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        if (self.abbreviation == 'FCM' && !self.operations['Create Global'] && !self.operations['Create Global']) return false;
                        if (!self.hasGlobal
                            || !self.operations.Update
                            || self.anySelectedFileIsLocked()
                            || self.isNonEditableFolder(self.selectedFolder)
                            || !self.manager.selectedRows().length
                            || self.manager.selectedRows().some(f =>
                                f.isFolder
                                || !self.hasGlobal
                                || self.hasGlobal && f.global == 1
                                || f.isLocked)
                        )
                            return false;
                        else return true;
                    },
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (row.itemModuleId) return false;
                        if (self.abbreviation == 'FCM' && !row.operations?.CreateGlobal) return false;
                        if (self.versionId) return false;
                        if ((row.id == RECYCLE_BIN_NODE_ID && row.isFolder)
                            || self.selectedFolder.id == ARCHIVE_NODE_ID
                            || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID)
                            return false;
                        if (row.isFolder
                            || !self.hasGlobal
                            || self.hasGlobal && row.global == 1
                            || self.isLocked
                            || !row.operations?.Update
                            || self.selectedFolder.id == RECYCLE_BIN_NODE_ID)
                            return false;
                        else return true;
                    },
                    click: function (rows) {
                        self.unsetGlobal(rows);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (row.itemModuleId || row.isReadOnly) return false;
                        if (self.abbreviation == 'FCM' && !row.operations?.DeleteGlobal && row.global > 1) return false;
                        return (row.operations?.Delete)
                            && self.selectedFilesOrCurrentRow(row)
                                .every(f =>
                                    f.isFolder && !f.isLocked && !self.isNonEditableFolder(f)
                                    || !f.isFolder && !f.isLocked)
                            ? true : false;
                    },
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.itemModuleId == PROJECT_MODULE_ID || self.selectedFolder.itemModuleId == PROGRAM_MODULE_ID) return false;
                        return (self.operations.Delete)
                            && self.selectedFolder.id != RECYCLE_BIN_NODE_ID
                            && self.selectedFilesOrCurrentFolder()
                                .every(f =>
                                    f.isFolder && !f.isLocked && !self.isNonEditableFolder(f)
                                    || !f.isFolder && !f.isLocked)
                            ? true : false;
                    },
                    click: function (rows) {
                        if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                            var archiveIds = [];
                            var size = 1;
                            if (rows.archiveId) {
                                archiveIds.push(rows.archiveId);
                                size = rows.filesNumber;
                            }
                            else {
                                archiveIds = rows.map((r) => r.archiveId);
                            }
                            self.deleteArchives(archiveIds, size);
                        }
                        else if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                            var archiveFileIds = [];
                            if (rows.archiveFileId) {
                                archiveFileIds.push(rows.archiveFileId);
                            }
                            else {
                                archiveFileIds = rows.map((r) => r.archiveFileId);
                            }
                            self.deleteArchiveFiles(archiveFileIds);
                        }
                        else {
                            self.delete(rows);
                        }
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    name: 'Modules',
                    noLabel: true,
                    condition: function () {
                        return self.selectedFolder.id == ARCHIVE_NODE_ID ? true : false;
                    },
                    click: function (row) {
                        self.archiveModules(row);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.id != RECYCLE_BIN_NODE_ID
                            || !row.operations?.Delete) return false;
                        else return true;
                    },
                    name: "Permanently Delete",
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        if (self.selectedFolder.id != RECYCLE_BIN_NODE_ID
                            || !self.operations.Delete
                            || !self.manager.selectedRows().length
                        ) return false;
                        else return true;
                    },
                    icon: "mdi mdi-delete-forever",
                    click: function (rows) {
                        self.delete(rows, true);
                    }
                },
                {
                    setProperties: ACTIONS.RESTORE,
                    condition: function (row) {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        return row.operations?.Delete
                            && self.selectedFolder.id == RECYCLE_BIN_NODE_ID
                            && !row.isLocked
                            ? true : false;
                    },
                    conditionOnTop: function () {
                        if (self.abbreviation == 'FCM' && self.isContractClosed()) return false;
                        return self.operations.Delete
                            && self.selectedFolder.id == RECYCLE_BIN_NODE_ID
                            && self.manager.selectedRows().length
                            && !self.anySelectedFileIsLocked()
                            ? true : false;
                    },
                    click: function (rows) {
                        self.restore(rows);
                    }
                }
            ]
        });
        self.manager.set_Columns(self.managerSetup.columns);
        if (self.managerSetup.customFilters) {
            self.manager.customFilters = angular.copy(self.managerSetup.customFilters);
            // hook global to manager

            if ((self.manager.customFilters.filter(f => f.key == "isGlobal") || []).length > 0) {
                Object.defineProperty(self.manager.customFilters.filter(f => f.key == "isGlobal")[0], 'value', {
                    get: function () { return self.manager.isGlobalSearch },
                    set: function (value) { self.manager.isGlobalSearch = value; }
                });
            }
        }
    }

    Explorer1.prototype.generateParentsForArchive = function (folder) {
        if (folder.parents) {
            // get parents
            var parents = folder.parents;
            // add current parent if is necessary
            var folderId = folder.itemModuleId || folder.id == ARCHIVE_NODE_ID ? folder.id : folder.entityInstanceId;
            if (!parents.some(f => f.id == folderId)) {
                parents.push({ id: folderId, isEntityInstance: folder.id != ARCHIVE_NODE_ID, name: '' });
            }

            return parents;
        }
        else {
            // set current parent
            return [ { id: folder.itemModuleId ? folder.id : folder.entityInstanceId, isEntityInstance: folder.id != ARCHIVE_NODE_ID, name: '' } ];
        }
    }

    Explorer1.prototype.generateParentsStringForArchive = function (folder) {
        if (folder.parentsEIString) {
            return folder.parentsEIString + ',' + ((folder.itemModuleId || folder.id == ARCHIVE_NODE_ID ? folder.id : folder.entityInstanceId)?.toString() ?? '');
        }
        else {
            return (folder.itemModuleId || folder.id == ARCHIVE_NODE_ID ? folder.id : folder.entityInstanceId)?.toString() ?? '';
        }
    }

    var compare = function (a, b) {
        var ret;
        if (a.id == SHARED_WITH_ME_NODE_ID || a.id == RECYCLE_BIN_NODE_ID || a.id == ARCHIVE_NODE_ID) ret = -1;
        else if (b.id == SHARED_WITH_ME_NODE_ID || b.id == RECYCLE_BIN_NODE_ID || b.id == ARCHIVE_NODE_ID) ret = 1;
        else {
            ret = a.name > b.name ? 1 : -1;
            ret = a.name == b.name ? 0 : ret;
        }

        return ret;
    }

    Explorer1.prototype.isNonEditableFolder = function (folder) {
        return folder.id == RECYCLE_BIN_NODE_ID
            || folder.id == ARCHIVE_NODE_ID
            || folder.id == ARCHIVE_FILE_NODE_ID;
    }

    Explorer1.prototype.selectedFilesOrCurrentRow = function (row) {
        var selectedRows = this.manager.selectedRows();
        if (selectedRows.length) {
            return selectedRows;
        }
        else if (row) {
            return [row];
        }
        return [];
    }

    Explorer1.prototype.selectedFilesOrCurrentFolder = function () {
        var selectedRows = this.manager.selectedRows();
        if (selectedRows.length) {
            return selectedRows;
        }
        else if (this.selectedFolder) {
            return [this.selectedFolder];
        }
        return [];
    }

    Explorer1.prototype.anySelectedFileIsLocked = function () {
        return this.manager.selectedRows().some(r => r.isLocked);
    }

    Explorer1.prototype.canFilesDoActionPdfMarkup = function (rows) {
        var self = this;
        if (rows.length <= 0) {
            return false;
        }

        if (rows.some(r => r.isLocked)) {
            return false;
        }

        if (self.isPdfMarkup) {
            return true;
        }

        var isAllPdfs = true;
        for (var i = 0; i < rows.length; i++) {
            if (self.pdfMarkupMimeTypes.indexOf(rows[i].mimeType) < 0) {
                isAllPdfs = false;
                break;
            }
        }
        return isAllPdfs;
    }

    Explorer1.prototype.processListFolders = function (folder) {
        var self = this;

        var parent = self.findParentInTree(self.treeStructure, folder.id, !folder.itemModuleId, folder.parents);

        for (var i = 0; i < self.manager.rows.length; i++) {
            if ((self.manager.rows[i].permissions || []).length) {
                self.manager.rows[i].operations = {};
                for (var j = 0; j < self.manager.rows[i].permissions.length; j++) {
                    Object.assign(self.manager.rows[i].operations, Permissions[self.manager.rows[i].permissions[j]]);
                }
            }

            if ((self.manager.rows[i].isFolder || self.manager.rows[i].itemModuleId) && !self.manager.hasFilters && !self.manager.criteria) {
                if (self.getFolderFromLookup(self.manager.rows[i].id.toString(), self.manager.rows[i].parentsEIString)) {
                    for (var key in self.manager.rows[i])
                        if (self.manager.rows[i].hasOwnProperty(key))
                            self.lookupFolders[self.computeFolderId(self.manager.rows[i])][key] = self.manager.rows[i][key];
                } else {
                    self.lookupFolders[self.computeFolderId(self.manager.rows[i])] = self.manager.rows[i];

                    if (!self.selectedFolder.folders) self.selectedFolder.folders = [];
                    self.selectedFolder.folders.push(self.manager.rows[i]);
                }
            }

            if (folder && parent) {
                if (!parent.folders) {
                    parent.folders = [];
                }

                if (!parent.folders.some(f => f.id == self.manager.rows[i].id) && (self.manager.rows[i].isFolder || self.manager.rows[i].itemModuleId)) {
                    parent.folders.push(self.manager.rows[i]);
                }
            }
            else {
                if (!self.treeStructure.some(f => f.id == self.manager.rows[i].id) && (self.manager.rows[i].isFolder || self.manager.rows[i].itemModuleId)) {
                    self.treeStructure.push(self.manager.rows[i]);
                }
            }
        }

        if (parent) {
            parent.expanded = true;
        }

        if (self.selectedFolder.folders) {
            self.selectedFolder.folders.sort(compare);
        }
    }

    Explorer1.prototype.findParentInTree = function (folders, designatedFolderId, isFolder, designatedParents) {
        var self = this;

        for (var i = 0; i < folders.length; i++) {
            if (folders[i].id == designatedFolderId // filter by folder id
                && (isFolder && !folders[i].itemModuleId || !isFolder && folders[i].itemModuleId) // filter by folder id or by entity instance id
                && self.validateParents(designatedParents, folders[i].parents) // filter by parents, we can have the same folder id (globals) in multiple contracts open
            ) {
                return folders[i];
            }

            if (folders[i].folders) {
                var child = self.findParentInTree(folders[i].folders, designatedFolderId, isFolder, designatedParents);
                if (child) {
                    return child;
                }
            }
        }

        return null;
    }

    Explorer1.prototype.validateParents = function (designatedParents, toCompareParents) {
        var designatedParentIds = (designatedParents || []).map(f => f.id);
        var toCompareParentIds = (toCompareParents || []).map(f => f.id);

        return JSON.stringify(designatedParentIds) === JSON.stringify(toCompareParentIds);
    }

    Explorer1.prototype.restoreSearch = function () {
        var self = this;
        var p = $q.defer();

        self.manager.restoreSearch();
        self.getTreeParents(self.manager.searchObject.additionalSearchInfo, true).finally(function () { p.resolve(); });

        return p.promise;
    }

    Explorer1.prototype.clearSearch = function () {
        var self = this;
        self.manager.searchObject = null;
    }

    Explorer1.prototype.computeFolderId = function (folder, parentsEIString) {
        if (angular.isNumber(folder)) {
            return (parentsEIString || '') + '|' + folder.toString()
        }
        else {
            return folder.itemModuleId || folder.isEntityInstance ? folder.id + "_1" : (folder.parentsEIString || parentsEIString || '') + '|' + folder.id.toString();
        }
    }

    Explorer1.prototype.clearFolderId = function (folderId) {
        if (folderId && !angular.isNumber(folderId) && folderId.endsWith("_1")) {
            return parseInt(folderId.replace("_1", ""));
        }
        else if (folderId && !angular.isNumber(folderId) && folderId.contains("|")) {
            return parseInt(folderId.substring(folderId.lastIndexOf('|') + 1));
        }
        return folderId;
    }

    Explorer1.prototype.getFolderContent = function (folder, isTree, isRestoreSearch) {
        var self = this;
        var p = $q.defer();
        if (isTree && folder.itemModuleId) {
            self.currentEntityInstanceId = self.getEntityInstanceIdFromDatalist(folder);
            self.populateBreadcrumbForSearch(folder);
        }
        if (isTree && (self.manager.searchObject || {}).isSearchView) self.manager.searchObject.isSearchView = false;
        if (!(self.manager.searchObject || {}).isSearchView) self.manager.criteria = '';
        else {
            self.manager.criteria = self.manager.searchObject.dataParams.criteria;
            self.manager.isGlobalSearch = self.manager.searchObject.dataParams[self.manager.globalSearchParam];
        }
        if (folder) var folder = self.getFolderFromLookup(folder.id, folder.parentsEIString) || folder;

        if (folder) {
            // in case we click on an archive row, then we need to keep the parent to send it to breadcrumbs
            if (folder.id == ARCHIVE_FILE_NODE_ID) {
                self.parentFolder = self.selectedFolder;
            }

            self.selectedFolder = folder;
            var otherParams = self.manager.searchObject && isRestoreSearch
                                ? self.manager.searchObject.urlParams 
                                : { id: folder.itemModuleId ? ROOT_NODE_ID : self.clearFolderId(self.selectedFolder.id) || ROOT_NODE_ID, parentName: (self.selectedFolder.name || "root") };

            if (folder.id == RECYCLE_BIN_NODE_ID) {
                otherParams.isDeletedItems = true;
            }
            else {
                otherParams.isDeletedItems = false;
            }
            self.manager.otherParams = self.setParams(otherParams);

        } else {
            folder = self.selectedFolder;
        }
        self.populateBreadcrumb(folder, self.parentFolder);

        // if we click on an entity instance, then we need to send current entity instance
        // but if we click on a folder we need to take parentEntityInstanceId because in case we click on a topper level being on a lower level, currentEntityInstanceId will be the lower one
        var entityInstanceId = folder?.itemModuleId ? self.currentEntityInstanceId : folder?.parentEntityInstanceId ?? self.currentEntityInstanceId;

        self.manager.additionalSearchInfo = angular.copy(folder);
        if (!self.manager.additionalSearchInfo.contractName)
            self.manager.additionalSearchInfo.contractName = userService.system.context.contract?.name;
        if (!self.manager.additionalSearchInfo.entityInstanceId) {
            self.manager.additionalSearchInfo.entityInstanceId = entityInstanceId;
        }

        if ((folder || {}).id == ARCHIVE_NODE_ID) {
            self.manager.dataURL = URI.CT.SEARCH_ARCHIVES;
            self.manager.urlParams = { entityInstanceId: entityInstanceId };
            self.manager.searchColumns = self.managerSetup.archiveColumns;
            self.manager.globalSearchColumns = self.managerSetup.archiveColumns;
            self.manager.nodeType = ARCHIVE_NODE_ID;
        }
        else if ((folder || {}).id == ARCHIVE_FILE_NODE_ID) {
            self.manager.dataURL = URI.CT.SEARCH_ARCHIVE_FILES;
            self.manager.urlParams = { archiveId: folder.archiveId };
            self.manager.entityInstanceId = folder.entityInstanceId;
            self.manager.searchColumns = self.managerSetup.archiveFileColumns;
            self.manager.globalSearchColumns = self.managerSetup.archiveFileColumns;
            self.manager.nodeType = ARCHIVE_FILE_NODE_ID;
        }
        else if ((folder || {}).itemModuleId) {
            self.manager.dataURL = self.urlsData.manager.url;
            self.manager.urlParams = self.manager.searchObject && isRestoreSearch
                                        ? self.manager.searchObject.urlParams 
                                        : { entityInstanceId: entityInstanceId, rootModuleId: self.rootModuleId };
            self.manager.searchColumns = self.managerSetup.searchColumns;
            self.manager.globalSearchColumns = self.managerSetup.globalSearchColumns;
            self.manager.nodeType = RECYCLE_BIN_NODE_ID;
        }
        else {
            self.manager.dataURL = self.urlsData.manager.url;
            self.manager.urlParams = self.manager.searchObject && isRestoreSearch
                                        ? self.manager.searchObject.urlParams 
                                        :{ entityInstanceId: entityInstanceId, rootModuleId: self.rootModuleId };
            self.manager.searchColumns = self.managerSetup.searchColumns;
            self.manager.globalSearchColumns = self.managerSetup.globalSearchColumns;
            self.manager.nodeType = entityInstanceId;
        }
        self.manager.options.sort = [];

        self.manager.loadPage(null, null, true)
            .then(function () {
                if ((folder || {}).id == RECYCLE_BIN_NODE_ID) { // recycle bin folder
                    if (self.managerSetup.recycleBinColumns) {
                        self.recycleBinView = true;

                        //do not modify the order of these lines as it will create regressions !!!
                        self.manager.layoutCols[1] = self.managerSetup.recycleBinColumns;
                        if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                    }
                }
                else if ((folder || {}).id == ARCHIVE_NODE_ID) { // archive documents folder                         
                    if (self.managerSetup.archiveColumns) {
                        self.recycleBinView = true;

                        //do not modify the order of these lines as it will create regressions !!!
                        self.manager.layoutCols[1] = self.managerSetup.archiveColumns;
                        if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                    }
                }
                else if ((folder || {}).id == ARCHIVE_FILE_NODE_ID) { // archive files folder                         
                    if (self.managerSetup.archiveFileColumns) {
                        self.recycleBinView = true;

                        //do not modify the order of these lines as it will create regressions !!!
                        self.manager.layoutCols[1] = self.managerSetup.archiveFileColumns;
                        if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                    }
                }
                else {
                    if (self.recycleBinView) {
                        self.recycleBinView = false;
                    }

                    if ((self.manager.searchObject || {}).isSearchView) {
                        self.manager.set_Columns(self.manager.layoutCols[1]);
                    }
                    else {
                        self.manager.set_Columns(self.managerSetup.columns);
                    }

                    //this is the root and it is not a search view
                    if (folder.id == self.currentEntityInstanceId && !(self.manager.searchObject || {}).isSearchView && self.abbreviation == "FCM" && folder.itemModuleId == CONTRACT_MODULE_ID  && !self.isExtraPermissions) {
                        // add the Archives node                            
                        var archive = {
                            id: ARCHIVE_NODE_ID,
                            createdBy: "CIPO Admin",
                            createdOn: "06/29/2022",
                            updatedOn: "06/29/2022",
                            canDelete: true,
                            global: 2,
                            mimeTypeId: 0,
                            name: "Archives",
                            parentId: - 1,
                            parentName: "Root",
                            folderPath: ".../Archives/",
                            folderPosition: 0,
                            isDeleted: false,
                            isFolder: true,
                            isLocked: false,
                            length: "-",
                            parents: self.generateParentsForArchive(folder),
                            parentsEIString: self.generateParentsStringForArchive(folder),
                            parentEntityInstanceId: folder.itemModuleId ? folder.id : folder.entityInstanceId,
                            parentsString: "-1",
                            setCompanyLogo: false,
                            setUserLogo: false,
                            version: 0,
                            versionId: 0,
                            _big_name: '<span class="fileInfo folderIcon  ellipsedText"><i class="mdi mdi-archive fmSymbol successText"></i>Archives</span>',
                            _name: '<span class="fileInfo folderIcon "><i class="mdi mdi-archive fmSymbol successText"></i>Archives</span>',
                            _hasShared: "-"
                        }

                        self.manager.rows.unshift(archive);
                    }

                    self.processListFolders(folder);
                    if (!self.isInExpandedFolders(folder.id, folder.parentsEIString)) self.expandedFolders.push(folder);

                    if (!folder.expanded) {
                        folder.expanded = true;
                    }
                    if (self.destinationFolderFromSearch) {
                        self.loading = true;
                    }

                    if (!folder.isChildrenLoaded) {
                        if (self.manager.hasFilters || self.manager.criteria) {
                            self.loading = true;
                        }
                        self.getChildren(folder, true)
                    }
                }

                //console.error('folder in get-content', folder);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            })
        return p.promise;
    }

    Explorer1.prototype.getEntityInstanceIdParent = function () {
        return userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId;
    }

    Explorer1.prototype.getCurrentEntityInstanceNameParent = function () {
        if (userService.system.context.contract) {
            return (userService.system.context.contract.no ? userService.system.context.contract.no : '') + (userService.system.context.contract.no ? ' - ' : '') + userService.system.context.contract.name;
        }
        else if (userService.system.context.project) {
            return (userService.system.context.project.no ? userService.system.context.project.no : '') + (userService.system.context.project.no ? ' - ' : '') + userService.system.context.project.name;
        }
        else if (userService.system.context.program) {
            return (userService.system.context.program.no ? userService.system.context.program.no : '') + (userService.system.context.program.no ? ' - ' : '') + userService.system.context.program.name;
        }

        return '';
    }

    Explorer1.prototype.getRootModuleId = function () {
        return userService.system.context.contract?.moduleId ?? userService.system.context.project?.moduleId ?? userService.system.context.program?.moduleId;
    }

    Explorer1.prototype.getTreeParents = function (folder, isRestoreSearch) {
        var self = this;
        var p = $q.defer();
        self.manager.loading = true;
        self.populateBreadcrumbForSearch(folder);
        self.getFolderContent(self.getFolderFromLookup(folder.id, folder.parentsEIString), false, isRestoreSearch).finally(function () { p.resolve(); });

        return p.promise;
    }

    Explorer1.prototype.processTreeFolders = function (folder, list) {
        var self = this;

        // add the Archives node
        if (list != null && self.abbreviation == "FCM" && folder.itemModuleId == CONTRACT_MODULE_ID && !self.isExtraPermissions) {
            var archive = {
                id: ARCHIVE_NODE_ID,
                canDelete: true,
                global: 2,
                mimeTypeId: 0,
                name: "Archives",
                parentId: ROOT_NODE_ID,
                parentName: "Root",
                folderPath: ".../Archives/",
                folderPosition: 0,
                isDeleted: false,
                isFolder: true,
                isLocked: false,
                length: "-",
                parents: self.generateParentsForArchive(folder),
                parentsEIString: self.generateParentsStringForArchive(folder),
                parentEntityInstanceId: folder.itemModuleId ? folder.id : folder.entityInstanceId,
                parentsString: "-1",
                setCompanyLogo: false,
                setUserLogo: false,
                version: 0,
                versionId: 0,
                _hasShared: "-"
            };

            list.unshift(archive);
        }

        list = list ? self.parseManagerData(list) : [];

        var parent = self.findParentInTree(self.treeStructure, folder.id, !folder.itemModuleId, folder.parents);
        for (var i = 0; i < list.length; i++) {
            if ((list[i].permissions || []).length) {
                list[i].operations = {};
                for (var j = 0; j < list[i].permissions.length; j++) {
                    Object.assign(list[i].operations, Permissions[list[i].permissions[j]]);
                }
            }

            if (list[i].isFolder || list[i].itemModuleId) {
                if (self.getFolderFromLookup(list[i].id, list[i].parentsEIString)) {
                    for (var key in list[i])
                        if (list[i].hasOwnProperty(key))
                            self.lookupFolders[self.computeFolderId(list[i])][key] = list[i][key];
                } else {
                    self.lookupFolders[self.computeFolderId(list[i])] = list[i];

                    if (!self.getFolderFromLookup(folder.id, folder.parentsEIString).folders) {
                        self.getFolderFromLookup(folder.id, folder.parentsEIString).folders = [];
                    }
                    self.getFolderFromLookup(folder.id, folder.parentsEIString).folders.push(list[i]);
                }
            }

            if (folder && parent) {
                if (!parent.folders) {
                    parent.folders = [];
                }

                if (!parent.folders.some(f => f.id == list[i].id) && (list[i].isFolder || list[i].itemModuleId)) {
                    parent.folders.push(list[i]);
                }
            }
            else {
                if (!self.treeStructure.some(f => f.id == list[i].id) && (list[i].isFolder || list[i].itemModuleId)) {
                    self.treeStructure.push(list[i]);
                }
            }
        }

        if (self.selectedFolder.folders) self.selectedFolder.folders.sort(compare);
    }

    Explorer1.prototype.getEntityInstanceIdFromDatalist = function (element) {
        if (element.itemModuleId) {
            if (element.itemModuleId == CONTRACT_MODULE_ID) {
                return element.contractEntityInstanceId || element.entityInstanceId;
            }
            else if (element.itemModuleId == PROJECT_MODULE_ID) {
                return element.projectEntityInstanceId || element.entityInstanceId;
            }
            else if (element.itemModuleId == PROGRAM_MODULE_ID) {
                return element.programEntityInstanceId || element.entityInstanceId;
            }
        }

        return null;
    }

    Explorer1.prototype.getChildren = function (folder, status, isTree) {
        var self = this;
        var p = $q.defer();
        try {
            if (isTree && folder.itemModuleId) {
                self.currentEntityInstanceId = self.getEntityInstanceIdFromDatalist(folder);
                self.populateBreadcrumbForSearch(folder);
            }

            // if we click on an entity instance, then we need to send current entity instance
            // but if we click on a folder we need to take parentEntityInstanceId because in case we click on a topper level being on a lower level, currentEntityInstanceId will be the lower one
            var entityInstanceId = folder?.itemModuleId ? self.currentEntityInstanceId : folder?.parentEntityInstanceId ?? self.currentEntityInstanceId;

            if (folder) folder.expanded = status;
            if (status && folder && !folder.isChildrenLoaded) {
                folder.loadingChildren = true;
                self[self.urlsData.tree.url.method](self.urlsData.tree.url, { url: self.setParams({ isFolders: true, entityInstanceId: entityInstanceId, rootModuleId: self.rootModuleId }), urltype: 'obj', body: { id: folder.itemModuleId ? ROOT_NODE_ID : self.clearFolderId(folder.id) || ROOT_NODE_ID, isFolders: true } })
                    .then(function (r) {
                        folder.isChildrenLoaded = true;
                        self.processTreeFolders(folder, r.data);
                        folder.expanded = true;
                        if (!self.isInExpandedFolders(folder.id, folder.parentsEIString)) self.expandedFolders.push(folder);

                        if (r && r.data && r.data.length && r.data.some(f => f.id != ARCHIVE_NODE_ID)) {
                            var notArchiveFolder = r.data.find(f => f.id != ARCHIVE_NODE_ID);

                            if (!self.selectedFolder.parentsEIString) {
                                self.selectedFolder.parentsEIString = notArchiveFolder.parentsEIString;
                            }

                            if (!self.selectedFolder.parents) {
                                self.selectedFolder.parents = notArchiveFolder.parents;
                            }
                        }

                        p.resolve();
                    })
                    .catch(function (e) { console.error(e); p.reject(e); Message.dberror(e); })
                    .finally(function () { folder.loadingChildren = false; self.loading = false; });


            }
        }
        catch (e) {
            console.error(e);
            p.reject();
        }

        return p.promise;
    }

    Explorer1.prototype.populateBreadcrumb = function (folder, parentFolder) {
        var self = this;
        var rootFolder = { id: ROOT_NODE_ID, folders: [], isLoadingChildren: true, itemModuleId: self.getRootModuleId(), name: "..." };
        // Start fresh with breadcrumbs
        self.breadcrumb = [];

        // 
        if (folder.id == ARCHIVE_FILE_NODE_ID) {
            folder.parents = self.generateParentsForArchive(parentFolder);
            folder.parentsEIString = self.generateParentsStringForArchive(parentFolder);
        }

        if (folder.parents && folder.parents.length) {
            for (var i = 0; i < folder.parents.length; i++) {
                if (self.lookupFolders[self.computeFolderId(folder.parents[i], folder.parentsEIString)])
                    self.breadcrumb.push(self.lookupFolders[self.computeFolderId(folder.parents[i], folder.parentsEIString)]);
                else if (folder.parents[i].id == ARCHIVE_NODE_ID) {
                    self.breadcrumb.push(self.lookupFolders[self.computeFolderId(folder.parents[i], folder.parentsEIString.replace(',' + ARCHIVE_NODE_ID, '').replace(ARCHIVE_NODE_ID + ',', ''))]);
                }
                else if (folder.parents[i].id == ROOT_NODE_ID) {
                    self.breadcrumb.push(rootFolder);
                }
            }

            if (folder.id == ARCHIVE_FILE_NODE_ID) {
                self.breadcrumb.push(folder);
            }
            else if (folder.id == ROOT_NODE_ID && !self.breadcrumb.some(f => f.id == ROOT_NODE_ID)) {
                self.breadcrumb.push(rootFolder);
            }
            else if (!self.breadcrumb.some(f => f.id == folder.id)) {
                self.breadcrumb.push(self.lookupFolders[self.computeFolderId(folder)]);
            }
        }
        else if (folder.itemModuleId) {
            self.breadcrumb.push(folder);
        }

        if (self.breadcrumb.length) {
            self.breadcrumbItemWidth = 100 / (self.breadcrumb.length + 1);
        }
        else {
            self.breadcrumbItemWidth = 100;
        }
    }

    Explorer1.prototype.populateBreadcrumbForSearch = function (folder) {
        var self = this;
        self.breadcrumbForSearch = [];
        var rootFolder = { id: ROOT_NODE_ID, folders: [], isLoadingChildren: true, itemModuleId: self.getRootModuleId(), name: "..." };
        if (folder.parents && folder.parents.length) {
            for (var i = 0; i < folder.parents.length; i++) {
                if (self.lookupFolders[self.computeFolderId(folder.parents[i], folder.parentsEIString)])
                    self.breadcrumbForSearch.push(self.lookupFolders[self.computeFolderId(folder.parents[i], folder.parentsEIString)]);
                else if (folder.parents[i].id == ROOT_NODE_ID) {
                    self.breadcrumbForSearch.push(rootFolder);
                }
            }

            if (!self.breadcrumbForSearch.some(f => f.id == folder.id)) {
                self.breadcrumbForSearch.push(self.lookupFolders[self.computeFolderId(folder)]);
            }
        }
        else if (folder.itemModuleId) {
            self.breadcrumbForSearch.push(folder);
        }

        if (self.breadcrumbForSearch.length) {
            self.breadcrumbForSearchItemWidth = 100 / (self.breadcrumbForSearch.length + 1);
        }
        else {
            self.breadcrumbForSearchItemWidth = 100;
        }
    }

    Explorer1.prototype.editItem = function (item, ev) {
        var self = this;
        // if (!(item || {}).id) var isAdd = true;
        var item = item ? self.setParams({ id: item.id, name: item.name, parentId: item.parentId, isFolder: item.isFolder })
            : self.setParams({ name: "", parentId: self.selectedFolder.id });

        var dataURL = item.id ?
            item.isFolder ? self.urlsData.folders.editFolderURL : self.urlsData.files.renameFileURL :
            self.urlsData.folders.addFolderURL;

        $mdDialog.show({
            locals: { item: item, dataURL: dataURL, showIcons: false, otherParams: self.setParams() },
            controller: 'renameItemController',
            templateUrl: '/ng/views/admin/modals/renameItem.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: self.isPdfMarkup || false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function () {


                self.getFolderContent(self.selectedFolder);
            }, function () {
                // cancel pressed
            });
    }

    Explorer1.prototype.getEntityInstanceModuleName = function () {
        var self = this;
        var moduleId = (self.getFolderFromLookup(self.currentEntityInstanceId)?.itemModuleId || CONTRACT_MODULE_ID);
        return userService.system.contextTree.modules.find(m => m.moduleId == moduleId)?.name || '';
    }

    Explorer1.prototype.editFolder = function (folder, ev) {
        var self = this;
        if (!(folder || {}).id) var isAdd = true;
        var item = folder
            ? self.setParams({
                id: self.clearFolderId(folder.id),
                name: folder.name,
                parentId: folder.itemModuleId ? ROOT_NODE_ID : folder.parentId,
                global: folder.global || 1,
                isLocked: folder.isLocked || self.selectedFolder.id == ARCHIVE_NODE_ID,
                isReadOnly: folder.isReadOnly,
                entityInstanceId: folder.entityInstanceId,
                createdBy: folder.createdBy,
                createdOn: folder.createdOn,
                createdOnNotFormatted: folder.createdOnNotFormatted,
                updatedBy: folder.updatedBy,
                updatedOn: folder.updatedOn,
                updatedOnNotFormatted: folder.updatedOnNotFormatted,
                folderPath: self.selectedFolder.id == RECYCLE_BIN_NODE_ID ? folder.pathWhenDeleted : folder.folderPath,
                moduleName: folder.moduleName,
                moduleAbbr: folder.moduleAbbr,
                permissions: folder.permissions,
                rootModuleId: folder.rootModuleId
            })
            // is file or new folder
            : self.setParams({ name: "", parentId: self.selectedFolder.itemModuleId ? ROOT_NODE_ID : self.selectedFolder.id, 
                               rootModuleId: self.selectedFolder.itemModuleId ?? self.selectedFolder.rootModuleId,
                               global: 1, permissions: userService.system.modules[self.abbreviation]?.operations?.map(s => s.id) ?? [] });

        if (!isAdd && !self.isProjectFiles) {
            item.description = '<h2>Folder Information</h2><div class="gridContainerx2" style="grid-gap: .5em;">'
                + '<p>Created on:<strong> ' + folder.createdOn
                + '</strong></p><p>Created by: <strong>' + folder.createdBy
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? 'Updated' : 'Deleted') + ' on:<strong> ' + folder.updatedOn
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? 'Updated' : 'Deleted') + ' by: <strong>' + folder.updatedBy
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? '' : 'Original ') + 'Location: <strong>' + folder.folderPath + '</strong></p>'
                + (folder.moduleName ? '<p>Destination for module: <strong>' + folder.moduleName + ' (' + folder.moduleAbbr + ')</strong></p>' : '')
                + (folder.global > 1 ? '<p><i class="mdi mdi-earth successText"></i> <strong>Global folder</strong></p>' : '')
                + '</div>';
        }
        else if (isAdd) {
            item.description = self.isProjectFiles ? self.getEntityInstanceModuleName() : '';
            item.folderPath = self.selectedFolder?.folderPath ?? self.selectedFolder?.name ?? '';
        }

        var urlParams = self.hasGlobal ? { entityInstanceId: self.currentEntityInstanceId } : {};

        item = new GenericClass(item, urlParams);

        GenericClass.prototype.formFields = function () {

            var formFields = {
                name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 }, editMode: !item.properties.isLocked }
            }

            return formFields;
        };

        GenericClass.prototype.resetPropertiesOnAddNew = { id: null, name: "" };

        GenericClass.prototype.urls = {
            create: function () { return self.urlsData.folders.addFolderURL },
            edit: function () { return self.urlsData.folders.editFolderURL },
            delete: function () { return null }
        };

        var options = {
            titles: { modal: item.properties.id ? 'Edit Folder' : 'Add Folder' },
            hasAddNew: true,
            hideSaveButtons: (folder || {}).moduleName || self.selectedFolder.id == RECYCLE_BIN_NODE_ID ? true : false
        }

        item.createForm();

        var controller = 'editFolderDialogController';
        var templateUrl = '/ng/views/admin/modals/editFolderDialog.html'

        if (self.abbreviation != 'FCM') {
            controller = 'genericClassController';
            templateUrl = '/ng/views/system/modals/genericClass.html'
        }

        $mdDialog.show({
            locals: { item: item, entityInstanceId: self.currentEntityInstanceId, rootModuleId: item.properties?.rootModuleId ?? self.rootModuleId, options: options },
            controller: controller,
            templateUrl: templateUrl,
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: self.isPdfMarkup || false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
        .then(function () {
            self.getFolderContent(self.selectedFolder);
        }, function (refresh) {
            // cancel pressed
            if (refresh) {
                self.getFolderContent(self.selectedFolder);
            }
        });
    }

    Explorer1.prototype.fileDetails = function (file, ev) {
        var self = this;

        var controller = 'editFileDialogController';
        var templateUrl = '/ng/views/admin/modals/editFileDialog.html'

        if (self.abbreviation != 'FCM') {
            controller = 'editFileController';
            templateUrl = '/ng/views/admin/modals/editFile.html'
        }

        $mdDialog.show({
            locals: { file: file, entityInstanceId: self.currentEntityInstanceId, rootModuleId: file.rootModuleId, dataURL: self.urlsData.files.fileDetailsURL, otherParams: self.setParams({ fileId: file.id }), viewMode: self.abbreviation == 'FCM' && self.isContractClosed() },
            controller: controller,
            templateUrl: templateUrl,
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: self.isPdfMarkup || false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
        // hide function
        .then(function () {
            self.getFolderContent(self.selectedFolder);
            self.clearStateParams();
        }, function (refresh) {
            // cancel pressed
            if (refresh) {
                self.getFolderContent(self.selectedFolder);
            }
            self.clearStateParams();
        });
    }

    Explorer1.prototype.clearStateParams = function () {
        $state.go('.', { f: '' }, { notify: false });
    }

    Explorer1.prototype.archiveModules = function (archive, ev) {
        var self = this;

        $mdDialog.show({
            locals: { modulesList: archive.modules },
            controller: 'archiveModulesController',
            templateUrl: '/ng/views/admin/modals/archiveModules.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: self.isPdfMarkup || false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            // hide function
            .then(function () {
            },
                // cancel function
                function () {
                });
    }

    var confirm = function (title, text, isMultiple) {
        var dialog = $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
        return dialog;
    }

    // Allows viewing of pdf/jpeg/png files via pspdfkit viewer
    Explorer1.prototype.view = function (row, event) {
        var viewPdfMarkup = this.pdfMarkupMimeTypes.includes(row.mimeType);
        var viewOffice = OFFICE_MIME_TYPES.includes(row.mimeType);
        if (viewPdfMarkup) {
            this.viewPdfMarkup(row, event);
        } else if (viewOffice) {
            this.viewOffice(row);
        }
    }

    Explorer1.prototype.viewOffice = function (row) {
        var self = this;

        var readOnly = self.isNonEditableFolder(self.selectedFolder)
            || !row.operations?.Update
            || row.isFolder
            || row.isLocked;

        officeService.open(row, readOnly);
    }

    // Allows viewing of pdf/jpeg/png files via pspdfkit viewer
    Explorer1.prototype.viewPdfMarkup = function (row, event) {
        var self = this;
        self.manager.loading = true;
        var uri = self.abbreviation == 'FCM'
            ? URI.PROJECT_FILE_MANAGER.GET_FILE_URL
            : URI.MY_CIPO_DRIVE.GET_FILE_URL;

        self[uri.method](`${uri}?versionId=${row.versionId}&isPreview=false`)
            .then(function (url) {
                $mdDialog.show({
                    locals: {
                        module: userService.system.modules['FCM'],
                        documentId: null,
                        fileUrl: url,
                        fileId: row.id,
                        mimeType: row.mimeType,
                        title: row.name,
                        entityInstanceId: self.currentEntityInstanceId
                    },
                    controller: 'pspdfkitViewerController',
                    templateUrl: '/ng/views/admin/modals/pspdfkitViewer.html',
                    parent: angular.element(document.body),
                    targetEvent: event,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false,
                    disableParentScroll: true,
                    multiple: true
                }).then(function (result) {
                }, function () {
                })
                    .finally(function () {
                        self.manager.loading = false;
                    });
            });
    }

    Explorer1.prototype.pdfMarkup = function (rows) {
        var self = this;
        self.manager.loading = true;
        // If single already uploaded row is clicked
        if (rows.length == 1 && rows[0].markupDocumentId) {
            var parameters = {
                tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId),
                d: rows[0].markupDocumentId,
                f: rows[0].id,
                n: rows[0].name
            };
            self.isPdfMarkup
                ? self.closeDialog(parameters.d, parameters.f, parameters.n)
                : self.navigateTo('tenant.pdfMarkup', parameters);
            return;
        }

        var payload = [];
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if (!row.markupDocumentId)
                payload.push({
                    fileId: row.id,
                    versionId: row.versionId,
                    fileName: row.name
                });
        }

        var uri = payload.length == 1
            ? URI.PDF_MARKUP.UPLOAD_DOCUMENT
            : URI.PDF_MARKUP.UPLOAD_DOCUMENTS;
        var pdfMarkupModuleId = userService.system.modules["PDFM"].moduleId;

        self[uri.method](`${uri}?moduleId=${pdfMarkupModuleId}&entityInstanceId=${self.currentEntityInstanceId}`,
            { body: payload.length == 1 ? payload[0] : payload },
            { headers: { moduleId: pdfMarkupModuleId } })
            .then(function (res) {
                (payload.length > 1 && res.length > 0)
                    ? Message.error(`Failed to load ${res.length}/${payload.length} selected files for markup`)
                    : Message.info(payload.length == 1 ? 'File loaded' : 'Files ready for markup');

                self.manager.loading = false;
                // Navigate to detail if single file. Otherwise, navigate to markup grid
                var parameters = payload.length == 1
                    ? { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId), d: res, f: payload[0].fileId, n: payload[0].fileName }
                    : { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId) };

                self.isPdfMarkup
                    ? payload.length == 1 ? self.closeDialog(parameters.d, parameters.f, parameters.n) : self.closeDialog()
                    : self.navigateTo('tenant.pdfMarkup', parameters);
            })
            .catch(function (err) {
                self.manager.loading = false;
                console.error(err);
                Message.error(`An error occurred when attempting to markup ${payload.length == 1 ? 'this PDF' : 'the selected PDFs'}.`);
            });
    }

    Explorer1.prototype.delete = function (rows, isPermanent) {
        var self = this;
        var dataURL = isPermanent ? self.urlsData.folders.permanentlyDeleteURL : self.urlsData.folders.deleteURL;
        var toSend = { files: [], foldersId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        // var itemsUnableToProcess = false;
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].isFolder) {
                    toSend.foldersId.push(rows[i].id);
                }
                else toSend.files.push({ id: rows[i].id, folderId: rows[i].parentId });
            }

            var modalTitle = isPermanent ? "Permanently Delete" : 'Delete';
            var modalText = isPermanent ? 'Are you sure you want to permanently delete the selected items?' : 'Are you sure you want to delete the selected items?';

            $mdDialog.show(confirm(modalTitle, modalText, self.isPdfMarkup))
                .then(function () {
                    self.manager.loading = true;
                    self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toSend })
                        .then(function (result) {
                            //moved to the Recycle Bin
                            Message.info('Item(s) deleted successfully.');
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;

                        })
                        .finally(function () {
                            if ((self.selectedFolder.folders || []).length) {
                                for (var i = 0; i < toSend.foldersId.length; i++) {
                                    self.selectedFolder.folders.splice(self.selectedFolder.folders.indexOf(self.getFolderFromLookup(toSend.foldersId[i], self.selectedFolder.parentsEIString)), 1);
                                    delete self.getFolderFromLookup(toSend.foldersId[i], rows[0].parentsEIString);

                                    if (!self.selectedFolder.folders || !self.selectedFolder.folders.length) {
                                        self.selectedFolder.hasChildren = false;
                                    }

                                    // this code will remove only folders from top level
                                    var treeItemPosition = self.treeStructure.indexOf(self.findParentInTree(self.treeStructure, toSend.foldersId[i], true, self.selectedFolder.parents));
                                    if (treeItemPosition != -1) {
                                        self.treeStructure.splice(treeItemPosition, 1);
                                    }
                                }
                            }

                            self.getFolderContent(self.selectedFolder);
                        });
                })
                .catch(function () {
                    // cancel pressed
                })

        } else {
            Message.info('Please select items to delete');
        }
    }

    Explorer1.prototype.deleteArchives = function (archiveIds, size) {
        var self = this;

        if (size == 0) {
            $mdDialog.show(confirm('Deleting Archive(s)', 'Are you sure you want to delete the selected Archive(s)?\r\nPlease be aware that  this is an irreversible process.'))
                .then(function () {
                    self.manager.loading = true;
                    $http[URI.CT.DELETE_ARCHIVES.method](URI.CT.DELETE_ARCHIVES.toString(), archiveIds)
                        .then(function () {
                            Message.info('Archive(s) deleted successfully');
                            self.getFolderContent(self.selectedFolder);
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;
                        })
                        .finally(function () {

                        });
                })
                .catch(function () {
                    // cancel pressed
                })
        }
        else {
            Message.stickyWarning("The selected archive cannot be deleted because it is not empty.");
        }
    }


    Explorer1.prototype.deleteArchiveFiles = function (archiveFileIds) {
        var self = this;

        $mdDialog.show(confirm('Deleting Archive File(s)', 'Are you sure you want to delete the selected archive file(s)?\r\nPlease be aware that  this is an irreversible process.'))
            .then(function () {
                self.manager.loading = true;
                $http[URI.CT.DELETE_ARCHIVE_FILES.method](URI.CT.DELETE_ARCHIVE_FILES.toString(), archiveFileIds)
                    .then(function () {
                        Message.info('Archive file(s) deleted successfully');
                        self.getFolderContent(self.selectedFolder);
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        self.manager.loading = false;
                    })
                    .finally(function () {

                    });
            })
            .catch(function () {
                // cancel pressed
            })
    }

    Explorer1.prototype.restore = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.folders.restoreURL;

        var toSend = { files: [], foldersId: [] };

        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var itemsUnableToProcess = false;

        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {

                if (rows[i].isFolder) {
                    toSend.foldersId.push(rows[i].id);
                }
                else toSend.files.push({ id: rows[i].id, folderId: rows[i].parentId });
            }

            if (!toSend.foldersId.length && !toSend.files.length) {
                Message.warning("You don't have the right to restore the items you selected.");
                return;
            }

            if (itemsUnableToProcess) {
                Message.warning("The items you don't have the right to restore were remove from the list.");
            }

            $mdDialog.show({
                locals: {
                    items: toSend,
                    options: { url: self.urlsData.folders.restoreURL },
                    entityInstanceId: self.currentEntityInstanceId
                },
            controller: 'restoreItemsController',
                templateUrl: '/ng/views/admin/modals/restoreItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            }).then(function () {
                self.getFolderContent(self.selectedFolder);
                self.reloadTree();
            }, function () {
                // cancel pressed
            });

        } else {
            Message.info('Please select items to restore');
        }
    }

    Explorer1.prototype.reloadTree = function () {
        var self = this;
        self.lookupFolders[self.currentEntityInstanceId + "_1"].isChildrenLoaded = false;
        self.getChildren(self.lookupFolders[self.currentEntityInstanceId + "_1"], true)
            .then(function () {
                for (var i = 0; i < self.expandedFolders.length; i++) {
                    if (self.expandedFolders[i].id != self.currentEntityInstanceId) {
                        self.expandedFolders[i].isLoadingChildren = false;
                        self.getChildren(self.getFolderFromLookup(self.expandedFolders[i].id, self.expandedFolders[i].parentsEIString), true)
                    }
                }
            })
    }

    Explorer1.prototype.downloadZipFile = function (rows) {
        var self = this;
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }

        var altrows = self.manager.selectedRows();

        // here if altrows has any entries, we combine altRows (the rows with boxes checked) with
        //  rows(the row the icon was pressed from), while excluding duplicates
        if (altrows.length > 0) {
            for (var row in altrows) {
                if (rows.filter(obj => {
                    return obj.id === altrows[row].id
                }).length == 0) {
                    rows.push(altrows[row]);
                }
            }
        }

        if (rows.some(r => r.itemModuleId)) {
            Message.warning('Only folders and files are allowed to be downloaded.');
            return;
        }

        var toDownload = { filesId: [], foldersId: [], rootModuleId: self.rootModuleId };

        if (rows && rows.length) {
            self.manager.loading = true;

            for (var i = 0; i < rows.length; i++) {
                if (rows[i].isFolder && rows[i].id && !rows[i].itemModuleId) {
                    toDownload.foldersId.push(rows[i].id);
                }
                else if (rows[i].id && !rows[i].itemModuleId) {
                    toDownload.filesId.push(rows[i].id);
                }
            }

            $http[self.urlsData.files.downloadZipFile.method](self.urlsData.files.downloadZipFile.toString() + (self.hasGlobal ? '?entityInstanceId=' + self.currentEntityInstanceId ?? '' : ''), toDownload, { responseType: "arraybuffer" })
                .then(function (result) {
                    if (result.status && result.status == 202) {
                        Message.stickyWarning("Download process has started and is running in the background. You will receive an email once it is completed");
                    } else {
                        var filename = 'Cipo-download';
                        var type = result.headers('Content-Type');
                        var disposition = result.headers('Content-Disposition');
                        if (disposition) {
                            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                            if (match[1])
                                filename = match[1];
                        }
                        filename = filename.replace(/[<>:"\/\\|?*]+/g, '_');
                        if (filename.startsWith('cipo-download-')) {
                            filename = filename + '.zip';
                        }

                        var blob = new Blob([result.data], { type: type });
                        FileSaver.saveAs(blob, filename);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () { self.manager.loading = false; });

        } else {
            Message.info('Please select items to download');
        }
    }

    Explorer1.prototype.moveItems = function (rows, ev) {
        var self = this;
        //var dataURL = self.urlsData.folders.moveURL;
        var toMove = { filesId: [], foldersId: [], rootModuleId: self.rootModuleId };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }

        var hasGlobalFolders = false;

        var globalFolders = rows.filter(r => r.isFolder && r.global > 1);
        var notGlobalFolders = rows.filter(r => r.isFolder && r.global == 1);

        if (globalFolders?.length && notGlobalFolders?.length) {
            Message.warning('Cannot move global and context folders simultaneously');
            return;
        }

        var readonlyFolders = rows.filter(r => r.isFolder && r.isReadOnly);
        if (readonlyFolders?.length) {
            Message.warning('Cannot move readonly folders');
            return;
        }

        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].isFolder) {
                    toMove.foldersId.push(rows[i].id);

                    if (rows[i].global > 1) {
                        hasGlobalFolders = true;
                    }
                }
                else toMove.filesId.push(rows[i].id);
            }

            var entityInstanceId = self.currentEntityInstanceId;
            var info = {
                modalName: 'Move items',
                saveButtonText: 'Move To',
                modalAction: "move",
                urlsData: self.urlsData,
                sourceFolderId: self.selectedFolder.id,
                otherParams: self.setParams({ entityInstanceId: entityInstanceId }),
                canUseRoot: true
            }

            $mdDialog.show({
                locals: {
                    items: toMove,
                    info: info,
                    entityInstanceId: entityInstanceId,
                    topLevelEntityInstanceId: self.getEntityInstanceIdParent(),
                    rootModuleId: self.getRootModuleId(),
                    isProjectFiles: self.isProjectFiles,
                    globalFolders: hasGlobalFolders
                },
                controller: 'moveItemsController',
                templateUrl: '/ng/views/admin/modals/moveItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    self.getFolderContent(self.selectedFolder)
                        .then(function () {
                            if (toMove.foldersId.length) {
                                for (var i = 0; i < toMove.foldersId.length; i++) {
                                    self.selectedFolder.folders.splice(self.selectedFolder.folders.indexOf(self.getFolderFromLookup(toMove.foldersId[i], self.selectedFolder.parentsEIString + ',' + self.selectedFolder.id)), 1);
                                    delete self.getFolderFromLookup(toMove.foldersId[i], rows[0].parentsEIString);
                                }
                            }
                            var parent = (result == entityInstanceId || !self.getFolderFromLookup(result, self.selectedFolder.parentsEIString)) ? { id: entityInstanceId + "_1" } : self.getFolderFromLookup(result, self.selectedFolder.parentsEIString);
                            self.getChildren(parent, true);
                        })

                }, function () {
                    // cancel pressed
                });

        } else {
            Message.info('Please select items to move');
        }
    }

    Explorer1.prototype.shareItems = function (rows, ev) {
        var self = this;
        //var dataURL = self.urlsData.folders.moveURL;
        var toShare = { filesId: [], usersId: [], files: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }

        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.files.push(rows[i]);
                    toShare.filesId.push(rows[i].id);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

            $mdDialog.show({
                locals: { items: toShare, urlData: self.urlsData.files.shareFileURL },
                controller: 'shareItemsController',
                templateUrl: '/ng/views/admin/modals/shareItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    for (var i = 0; i < toShare.files.length; i++) {
                        toShare.files[i].selected = false;
                    }
                    self.manager.loadPage();
                }, function () {
                    // cancel pressed
                });


        } else {
            Message.info('Please select files');
        }
    }

    Explorer1.prototype.unshareItems = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.unshareFileURL;
        var toUnshare = { list: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toUnshare.list.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toUnshare.list.length) {
                Message.info('Please select files');
                return;
            }

            $mdDialog.show(confirm('Unshare file(s)', 'Are you sure you want to unshare the selected item(s)?', self.isPdfMarkup))
                .then(function () {
                    self.manager.loading = true;
                    self[dataURL.method](dataURL, { url: { parentId: self.selectedFolder.id }, urltype: "obj", body: toUnshare })
                        .then(function (result) {

                            Message.info('The selected files were unshared successfully.');
                            self.manager.loadPage();
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                        })
                        .finally(function () {
                            // self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                })

        } else {
            Message.info('Please select files');
        }
    }

    Explorer1.prototype.setGlobal = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.globalFileURL;
        var toShare = { filesId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.filesId.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

            $mdDialog.show(confirm('Set global', 'Are you sure you want to set global the selected item(s)?', self.isPdfMarkup))
                .then(function () {
                    self.manager.loading = true;
                    self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toShare })
                        .then(function (result) {

                            Message.info('The selected files were set global successfully.');
                            for (var i = 0; i < files.length; i++) {
                                files[i].selected = false;
                            }
                            self.manager.loadPage();
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;

                        })
                        .finally(function () {
                            // self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                })


        } else {
            Message.info('Please select files');
        }


    }

    Explorer1.prototype.unsetGlobal = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.unsetGlobalFileURL;
        var toShare = { filesId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.filesId.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

            $mdDialog.show(confirm('Unset global', 'Are you sure you want to unset global the selected item(s)?', self.isPdfMarkup))
                .then(function () {
                    self.manager.loading = true;
                    self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toShare })
                        .then(function (result) {

                            Message.info('The selected files were unset global successfully.');
                            for (var i = 0; i < files.length; i++) {
                                files[i].selected = false;
                            }
                            self.manager.loadPage();
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;

                        })
                        .finally(function () {
                            //self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                })


        } else {
            Message.info('Please select files');
        }


    }

    Explorer1.prototype.downloadFile = function (row) {
        var self = this;
        row.isBusy = true;
        var uri = self.abbreviation == 'FCM'
            ? URI.PROJECT_FILE_MANAGER.GET_FILE_URL
            : URI.MY_CIPO_DRIVE.GET_FILE_URL;

        self[uri.method](`${uri}?versionId=${row.versionId}&isPreview=false`)
            .then(function (r) {
                var link = document.createElement("a");
                link.href = r;
                link.download = row.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(function (e) {
                Message.dberror(e);
            }).finally(function () {
                row.isBusy = false;

            })
    }

    Explorer1.prototype.downloadArchiveFile = function (row) {
        var self = this;
        row.isBusy = true;
        var uri = URI.CT.GET_ARCHIVE_FILE_URL;

        // Creates a hidden link element that clicks itself to download the provided file            
        self[uri.method](`${uri}?entityInstanceId=${row.entityInstanceId}&fileName=${row.archiveFileName}`)
            .then(function (r) {
                var link = document.createElement("a");
                link.href = r;
                link.download = row.archiveFileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(function (e) {
                Message.dberror(e);
            }).finally(function () {
                row.isBusy = false;
            })
    }


    Explorer1.prototype.setAsUserAvatar = function (row) {
        var self = this;
        var dataURL = self.urlsData.files.setAsUserAvatar;
        self[dataURL.method](dataURL, { url: { fileId: row.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info("Profile image set successfully");
                userService.User();
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    Explorer1.prototype.setAsCompanyLogo = function (row) {
        var self = this;
        var dataURL = self.urlsData.files.setAsCompanyLogo;
        self[dataURL.method](dataURL, { url: { fileId: row.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info("Company logo set successfully");
                userService.Reload_Tenant_Logos();
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    Explorer1.prototype.isContractClosed = function () {
        var self = this;
        return userService.isContractClosed(null, self.currentEntityInstanceId);
    }

    return Explorer1;
});